import React, { Component } from 'react';

export default class NotFound extends Component {
  
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <div>
        404 Not Found
      </div>
    );
  }
}